<template>
  <el-dialog
    :visible.sync="dialogVisible"
    top="1%"
    width="80%"
    title="关联合同"
    :center="true"
    :before-close="doClose"
  >
    <section class="Coursecontract">
      <div class="operationControl" style="border:0">
        <div class="searchbox">
          <div title="合同名称" class="searchboxItem">
            <span  class="itemLabel">合同名称:</span>
            <el-input
              clearable
              v-model="contractName"
              type="text"
              size="small"
              placeholder="请输入合同名称"
            />
          </div>
          <div title="签订机构" class="searchboxItem ci-full">
          <span class="itemLabel" >签订机构:</span>
          <el-select
            size="small"
            v-model="signCompId"
            :remote-method="getCompanyList"
            remote
            filterable
            clearable
            placeholder="请至少输入两个字搜索"
          >
            <el-option
              v-for="item in CompanyList"
              :key="item.compId"
              :label="item.compName"
              :value="item.compId"
            ></el-option>
          </el-select>
        </div>
         <div title="合同类型" class="searchboxItem ci-full">
              <span class="itemLabel">合同类型:</span>
              <el-select
                size="small"
                v-model="contractType"
                clearable
                placeholder="请选择合同类型"
              >
                <el-option
                  v-for="item in contractTypeList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
          <div>
            <el-button class="bgc-bv" @click="getData()">查询</el-button>
          </div>
        </div>
      </div>
      <div class="framePage-scroll">
        <div class="ovy-a">
          <el-table
            ref="multipleTable"
            :data="tableData"
            :height="tableHeight"
            size="small"
            tooltip-effect="dark"
            row-key="kpointId"
            style="width: 100%"
            stripe
            border
          >
           <el-table-column label width="35px" align="center">
            <template slot-scope="scope">
              <el-radio v-model="radio" :label="scope.row" />
            </template>
          </el-table-column>
            <el-table-column label="合同名称" align="center" show-overflow-tooltip prop="contractName"  minwidth="240"/>
             <el-table-column
            label="签订机构"
            align="center"
            show-overflow-tooltip
            prop="signCompName"
            minwidth="240"
          />
           <el-table-column
                label="合同类型"
                align="left"
                show-overflow-tooltip
                prop="contractType"
                width="80px"
              >
                <template slot-scope="scope">
                  {{
                    scope.row.contractType == "10"
                      ? "收款合同"
                      : scope.row.contractType == "20"
                      ? "付款合同"
                      : "备案合同"
                  }}
                </template>
              </el-table-column>
            <Empty slot="empty" />
          </el-table>
        </div>
      </div>
      <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      <span class="dialog-footer" style="margin-top:10px">
        <div>
          <el-button class="bgc-bv" @click="dialogClose">取消</el-button>
          <el-button class="bgc-bv" @click="doOk">确定</el-button>
        </div>
      </span>
    </section>
  </el-dialog>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import tree from "@/components/treePopup";
import { mapGetters } from "vuex";
export default {
  name: "courseMoreTable",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
     contractName:'',
signCompId:'',
contractType:'',
     radio:'',
      dialogVisible: false,
      start: false,
      CompanyList:[],
      contractTypeList: [
        {
          label: "收款合同",
          value: "10",
        },
        {
          label: "付款合同",
          value: "20",
        },
        {
          label: "备案合同",
          value: "30",
        },
      ],
    };
  },
  // watch: {
  //   compOwnerId: function(val) {
  //     if (val == "") {
  //       this.CompanyList = [];
  //     }
  //   }
  // },
  created() {},
  methods: {
    tinit() {
      this.init();
    },
    showPopUp() {
      this.dialogVisible = true;
      this.start = true;
      if(this.start){
          this.getData();
         
      }
    },
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };

      if (this.contractName) {
        params.contractName = this.contractName;
      }
      if (this.signCompId) {
        params.signCompId = this.signCompId;
      }
      if (this.contractType) {
        params.contractType = this.contractType;
      }
      if (this.start) {
        this.doFetch({
          url: "/biz/new/bill/contract/queryContractRelationList",
          params,
          pageNum
        });
      }
    },
     // 资源提供者
    getCompanyList(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/queryCompanyList", { compName: query })
          .then(res => {
            if (res.status == 0) {
              this.CompanyList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.CompanyList = [];
      }
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight = 760 - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 1) * 16;
      if (opDom) {
        tHeight -=  40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
    doOk() {
      const { radio } = this;
      if (radio) {
        this.$emit("eventBus", radio);
        this.doClose()
      } 
    },
    dialogClose() {
      this.dialogVisible = false;
    //   this.$emit("eventBus");
      // this.params = {};
    },
    doClose() {
      this.dialogVisible = false;
      this.radio = ''
    },
  }
};
</script>
<style lang="less" scoped>
.Coursecontract {

  .el-radio {
    .el-radio__label {
      display: none;
    }
    .el-radio__inner{
      width: 18px;
      height: 18px;
      border-color: #777;
    }
    .el-radio__inner:hover{
      border-color: #5c6be8;
    }
    .el-radio__input.is-checked .el-radio__inner{
      border-color: #5c6be8;
      background-color: #5c6be8;
    }
  }
}
</style>
<style lang="less" scope>
  .el-dialog--center .el-dialog__body {
    text-align: initial;
    padding: 20px 25px 30px;
}
</style>
