<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title" v-if="this.contract != 'contract'">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;" @click="$router.back()">代理商管理</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">代理商新增</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-form
              :model="ruleForm"
              :rules="rules"
              ref="ruleForm"
              label-width="120px"
              label-position="right"
              class="demo-ruleForm"
            >
              <el-form-item label="代理商类型" prop="agencyType">
                <el-radio-group
                  v-model="ruleForm.agencyType"
                  @change="agentChange"
                  :disabled="stu == 'edit'"
                >
                  <el-radio label="10">个人代理</el-radio>
                  <el-radio label="20">企业代理</el-radio>
                </el-radio-group>
              </el-form-item>
              <!-- <template v-if="ruleForm.agentType == '20'"> -->
              <el-form-item
                label="公司名称"
                prop="companyName"
                v-if="ruleForm.agencyType == '20'"
              >
                <el-input
                  v-model="ruleForm.companyName"
                  size="small"
                  maxlength="80"
                  show-word-limit
                ></el-input>
              </el-form-item>
              <el-form-item
                label="社会信用代码"
                prop="socialInfoCode"
                v-if="ruleForm.agencyType == '20'"
              >
                <el-input
                  v-model="ruleForm.socialInfoCode"
                  size="small"
                ></el-input>
              </el-form-item>
              <el-form-item
                label="公司地址"
                prop="companyAddress"
                v-if="ruleForm.agencyType == '20'"
              >
                <el-input
                  v-model="ruleForm.companyAddress"
                  size="small"
                  show-word-limit
                  maxlength="255"
                ></el-input>
              </el-form-item>
              <el-form-item label="营业执照" v-if="ruleForm.agencyType == '20'">
                <el-upload
                  :on-change="handlebusinessLicense"
                  :before-upload="$beforeAvatarUpload"
                  :http-request="$requestMine"
                  :show-file-list="false"
                  class="img-el-upload"
                  action
                  accept="image/png, image/gif, image/jpg, image/jpeg"
                >
                  <el-image
                    :src="
                      ruleForm.businessLicense ||
                        require('@/assets/develop.png')
                    "
                    fit="contain"
                    class="imgCenter"
                  ></el-image>
                </el-upload>
              </el-form-item>
              <el-form-item
                label="法人姓名"
                prop="legalName"
                v-if="ruleForm.agencyType == '20'"
              >
                <el-input
                  v-model="ruleForm.legalName"
                  size="small"
                  show-word-limit
                  maxlength="30"
                ></el-input>
              </el-form-item>
              <el-form-item
                label="法人身份证照"
                v-if="ruleForm.agencyType == '20'"
              >
                <div style="display:flex">
                  <div style="width:286px">
                    <el-upload
                      :on-change="handlelegalbusiness"
                      :before-upload="$beforeAvatarUpload"
                      :http-request="$requestMine"
                      :show-file-list="false"
                      class="img-el-upload"
                      action
                      accept="image/png, image/gif, image/jpg, image/jpeg"
                    >
                      <el-image
                        :src="
                          ruleForm.legalIdcardFront ||
                            require('@/assets/develop.png')
                        "
                        fit="contain"
                        class="imgCenter"
                      ></el-image>
                    </el-upload>
                  </div>
                  <div style="width:286px;margin-left:10px">
                    <el-upload
                      :on-change="handlelegalbusiness2"
                      :before-upload="$beforeAvatarUpload"
                      :http-request="$requestMine"
                      :show-file-list="false"
                      class="img-el-upload"
                      action
                      accept="image/png, image/gif, image/jpg, image/jpeg"
                    >
                      <el-image
                        :src="
                          ruleForm.legalIdcardBack ||
                            require('@/assets/develop.png')
                        "
                        fit="contain"
                        class="imgCenter"
                      ></el-image>
                    </el-upload>
                  </div>
                </div>
              </el-form-item>
              <el-form-item
                label="法人身份证号"
                v-if="ruleForm.agencyType == '20'"
                prop="legalIdcard"
              >
                <el-input
                  v-model="ruleForm.legalIdcard"
                  size="small"
                ></el-input>
              </el-form-item>
              <el-form-item
                label="法人手机号"
                v-if="ruleForm.agencyType == '20'"
              >
                <el-input
                  v-model="ruleForm.legalMobile"
                  size="small"
                ></el-input>
              </el-form-item>
              <!-- </template> -->
              <el-form-item label="联系人姓名" prop="agencyName">
                <el-input
                  v-model="ruleForm.agencyName"
                  size="small"
                  maxlength="30"
                  show-word-limit
                ></el-input>
              </el-form-item>
              <el-form-item label="联系电话" prop="agencyMobile">
                <el-input
                  v-model="ruleForm.agencyMobile"
                  size="small"
                ></el-input>
              </el-form-item>
              <el-form-item
                label="身份证照"
                prop="thumbnail"
                v-if="ruleForm.agencyType != '20'"
              >
                <div style="display:flex">
                  <div style="width:286px">
                    <el-upload
                      :on-change="handlebusinessLicense3"
                      :before-upload="$beforeAvatarUpload"
                      :http-request="$requestMine"
                      :show-file-list="false"
                      class="img-el-upload"
                      action
                      accept="image/png, image/gif, image/jpg, image/jpeg"
                    >
                      <el-image
                        :src="
                          ruleForm.agencyIdcardFront ||
                            require('@/assets/develop.png')
                        "
                        fit="contain"
                        class="imgCenter"
                      ></el-image>
                    </el-upload>
                  </div>
                  <div style="width:286px;margin-left:10px">
                    <el-upload
                      :on-change="handlebusinessLicense4"
                      :before-upload="$beforeAvatarUpload"
                      :http-request="$requestMine"
                      :show-file-list="false"
                      class="img-el-upload"
                      action
                      accept="image/png, image/gif, image/jpg, image/jpeg"
                    >
                      <el-image
                        :src="
                          ruleForm.agencyIdcardBack ||
                            require('@/assets/develop.png')
                        "
                        fit="contain"
                        class="imgCenter"
                      ></el-image>
                    </el-upload>
                  </div>
                </div>
              </el-form-item>
              <el-form-item label="身份证号" prop="agencyIdcard">
                <el-input
                  v-model="ruleForm.agencyIdcard"
                  size="small"
                ></el-input>
              </el-form-item>
              <el-form-item label="代理区域" prop="areaId">
                <el-cascader
                  v-model="ruleForm.areaId"
                  placeholder="请选择代理区域"
                  :options="areatreeList"
                  :props="propsarea"
                  size="small"
                ></el-cascader>
              </el-form-item>
              <el-form-item label="代理类型" prop="trainTypeId">
                <el-cascader
                  placeholder="请选择代理培训类型"
                  v-model="ruleForm.trainTypeId"
                  :options="trainTypeList"
                  :props="propsTrainType"
                  size="small"
                ></el-cascader>
              </el-form-item>
              <el-form-item label="合作状态" prop="agencyState">
                <el-select
                  v-model="ruleForm.agencyState"
                  placeholder="请选择"
                  size="small"
                >
                  <el-option
                    v-for="item in CooperationStatusList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="合作时间" prop="cooperationTime">
                <el-date-picker
                  v-model="ruleForm.cooperationTime"
                  type="daterange"
                  size="small"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                >
                </el-date-picker>
              </el-form-item>
              <el-form-item label="合作方式" prop="agencyCooperationType">
                <el-select
                  v-model="ruleForm.agencyCooperationType"
                  placeholder="请选择"
                  size="small"
                >
                  <el-option
                    v-for="item in CooperationModeList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="备注">
                <el-input
                  v-model="ruleForm.agencyRemark"
                  size="small"
                  type="textarea"
                  show-word-limit
                  maxlength="255"
                ></el-input>
              </el-form-item>
            </el-form>
            <div class="btn-box flexcc">
              <el-button @click="doCancel" class="bgc-bv">取 消</el-button>
              <el-button class="bgc-bv" @click="doAddSave('ruleForm')"
                >确 定</el-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "agentAdd",
  components: {},
  props: ["contract"],
  data() {
    var Phone = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入联系电话"));
      } else if (
        value &&
        !/^1[3456789]\d{9}$/.test(value) &&
        !/^([0-9]{3,4}-)?[0-9]{7,8}$/.test(value)
      ) {
        callback(new Error("请输入正确的联系电话"));
      } else {
        callback();
      }
    };
    var legalPhone = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入法人手机号"));
      } else if (
        value &&
        !/^1[3456789]\d{9}$/.test(value) &&
        !/^([0-9]{3,4}-)?[0-9]{7,8}$/.test(value)
      ) {
        callback(new Error("请输入正确的法人手机号"));
      } else {
        callback();
      }
    };
    var creditCode = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入社会信用代码"));
      } else if (
        value &&
        !/^([0-9A-HJ-NPQRTUWXY]{2}\d{6}[0-9A-HJ-NPQRTUWXY]{10}|[1-9]\d{14})$/g.test(
          value
        )
      ) {
        callback(new Error("请输入正确的社会信用代码"));
      } else {
        callback();
      }
    };
    return {
      id: "",
      stu: "add",
      ruleForm: {
        agencyType: "10",
        companyName: "",
        socialInfoCode: "",
        companyAddress: "",
        businessLicense: "",
        legalName: "",
        agencyName: "", //联系人姓名
        agencyMobile: "", //联系人手机号
        legalIdcard: "",
        legalIdcardFront: "",
        legalIdcardBack: "",
        agencyIdcardFront: "",
        agencyIdcardBack: "",
        agencyIdcard: "",
        areaId: [],
        trainTypeId: [],
        agencyState: "10",
        cooperationTime: "",
        agencyRemark: "",
        agencyCooperationType: "20",
      },
      CooperationStatusList: [
        {
          label: "合作中",
          value: "10",
        },
        {
          label: "停止合作",
          value: "20",
        },
      ],
      /* 合作方式 */
      CooperationModeList: [
        {
          label: "按学员",
          value: "10",
        },
        {
          label: "按机构",
          value: "20",
        },
      ],
      areatreeList: [],
      trainTypeList: [],
      propsarea: {
        value: "value",
        label: "label",
        disabled: "disabled",
        emitPath: false,
        checkStrictly: true,
        multiple: true,
      },
      propsTrainType: {
        value: "id",
        label: "label",
        disabled: "disabled",
        emitPath: false,
        checkStrictly: true,
        multiple: true,
      },
      rules: {
        agencyType: [
          { required: true, message: "请选择代理商类型", trigger: "change" },
        ],
        companyName: [
          { required: true, message: "请输入公司名称", trigger: "blur" },
        ],
        socialInfoCode: [
          { required: true, trigger: "blur", validator: creditCode },
        ],
        agencyName: [
          { required: true, message: "请输入联系人姓名", trigger: "blur" },
        ],
        agencyMobile: [{ required: true, validator: Phone, trigger: "blur" }],
        legalMobile: [
          { required: false, validator: legalPhone, trigger: "blur" },
        ],
        legalIdcard: [
          {
            required: false,
            validator: this.$validateIdCards,
            trigger: "blur",
          },
        ],
        agencyIdcard: [
          {
            required: false,
            validator: this.$validateIdCards,
            trigger: "blur",
          },
        ],
        areaId: [
          { required: true, message: "请选择代理区域", trigger: "change" },
        ],
        trainTypeId: [
          { required: true, message: "请选择代理培训类型", trigger: "change" },
        ],
        agencyState: [
          { required: true, message: "请选择合作状态", trigger: "change" },
        ],
        cooperationTime: [
          { required: true, message: "请选择合作时间", trigger: "change" },
        ],
        agencyCooperationType: [
          { required: true, message: "请选择合作方式", trigger: "change" },
        ],
      },
    };
  },
  computed: {},
  created() {
    this.stu = this.$route.query.stu;
    console.log(this.contract);
    this.getareatree();
    this.getTrainTypeList();
    if (this.stu == "edit") {
      this.getInfo(this.$route.query.id);
    }
  },
  methods: {
    getareatree() {
      this.$post("/sys/area/tree").then((ret) => {
        let data = ret.data || [];
        data.map((el) => {
          if (el.children) {
            el.children.map((els) => {
              els.disabled = false;
              if (els.children) {
                els.children.map((els1) => {
                  els1.disabled = false;
                });
              }
            });
          }
        });
        this.areatreeList = data;
      });
    },
    /**
     * tree接口
     */
    getTrainTypeList() {
      this.$post("/sys/category/train-type/tree")
        .then((res) => {
          let data = res.data || [];
          data.map((el) => {
            if (el.children) {
              el.children.map((els) => {
                els.disabled = false;
              });
            }
          });
          this.trainTypeList = data;
        })
        .catch((err) => {
          return;
        });
    },
    agentChange(val) {
      this.$refs["ruleForm"].clearValidate();
    },
    /* 上传营业执照图 */
    handlebusinessLicense(res) {
      this.uploadFetch(res.raw);
    },
    uploadFetch(file) {
      const fileType = file.name.slice(
        file.name.lastIndexOf(".") + 1,
        file.name.length
      );
      let formData = new FormData();
      formData.append("file", file);
      formData.append("fileType", fileType);
      formData.append("folder ", "LECTURER");
      const that = this;
      this.$Postformat("/sys/upload", formData).then((ret) => {
        that.ruleForm.businessLicense = ret.data.fileURL || "";
        that.ruleForm.businessLicenseKey = ret.data.fileKey || "";
      });
    },
    /* 上传法人身份证 */
    handlelegalbusiness(res) {
      this.uploadFetchlegal(res.raw);
    },
    uploadFetchlegal(file) {
      const fileType = file.name.slice(
        file.name.lastIndexOf(".") + 1,
        file.name.length
      );
      let formData = new FormData();
      formData.append("file", file);
      formData.append("fileType", fileType);
      formData.append("folder ", "LECTURER");
      const that = this;
      this.$Postformat("/sys/upload", formData).then((ret) => {
        that.ruleForm.legalIdcardFront = ret.data.fileURL || "";
        that.ruleForm.legalIdcardFrontKey = ret.data.fileKey || "";
      });
    },
    handlelegalbusiness2(res) {
      this.uploadFetchlegal2(res.raw);
    },
    uploadFetchlegal2(file) {
      const fileType = file.name.slice(
        file.name.lastIndexOf(".") + 1,
        file.name.length
      );
      let formData = new FormData();
      formData.append("file", file);
      formData.append("fileType", fileType);
      formData.append("folder ", "LECTURER");
      const that = this;
      this.$Postformat("/sys/upload", formData).then((ret) => {
        that.ruleForm.legalIdcardBack = ret.data.fileURL || "";
        that.ruleForm.legalIdcardBackKey = ret.data.fileKey || "";
      });
    },
    handlebusinessLicense3(res) {
      this.uploadFetchlegal3(res.raw);
    },
    uploadFetchlegal3(file) {
      const fileType = file.name.slice(
        file.name.lastIndexOf(".") + 1,
        file.name.length
      );
      let formData = new FormData();
      formData.append("file", file);
      formData.append("fileType", fileType);
      formData.append("folder ", "LECTURER");
      const that = this;
      this.$Postformat("/sys/upload", formData).then((ret) => {
        that.ruleForm.agencyIdcardFront = ret.data.fileURL || "";
        that.ruleForm.agencyIdcardFrontKey = ret.data.fileKey || "";
      });
    },
    handlebusinessLicense4(res) {
      this.uploadFetchlegal4(res.raw);
    },
    uploadFetchlegal4(file) {
      const fileType = file.name.slice(
        file.name.lastIndexOf(".") + 1,
        file.name.length
      );
      let formData = new FormData();
      formData.append("file", file);
      formData.append("fileType", fileType);
      formData.append("folder ", "LECTURER");
      const that = this;
      this.$Postformat("/sys/upload", formData).then((ret) => {
        that.ruleForm.agencyIdcardBack = ret.data.fileURL || "";
        that.ruleForm.agencyIdcardBackKey = ret.data.fileKey || "";
      });
    },
    /**
     * 新增
     */
    doAddSave(formName) {
      const params = {
        agencyType: this.ruleForm.agencyType,
        agencyName: this.ruleForm.agencyName,
        agencyMobile: this.ruleForm.agencyMobile,
        agencyIdcard: this.ruleForm.agencyIdcard,
        agencyIdcardFront: this.ruleForm.agencyIdcardFrontKey,
        agencyIdcardBack: this.ruleForm.agencyIdcardBackKey,
        areaIds: this.ruleForm.areaId,
        trainTypeIds: this.ruleForm.trainTypeId,
        agencyState: this.ruleForm.agencyState,
        agencyStartDate: this.ruleForm.cooperationTime[0],
        agencyEndDate: this.ruleForm.cooperationTime[1],
        agencyRemark: this.ruleForm.agencyRemark,
        companyName: this.ruleForm.companyName,
        socialInfoCode: this.ruleForm.socialInfoCode,
        companyAddress: this.ruleForm.companyAddress,
        businessLicense: this.ruleForm.businessLicenseKey,
        legalIdcardFront: this.ruleForm.legalIdcardFrontKey,
        legalIdcardBack: this.ruleForm.legalIdcardBackKey,
        legalIdcard: this.ruleForm.legalIdcard,
        legalMobile: this.ruleForm.legalMobile,
        legalName: this.ruleForm.legalName,
        agencyCooperationType: this.ruleForm.agencyCooperationType,
      };
      if (this.$route.query.id) {
        params.agencyId = this.$route.query.id;
      }
      if (this.ruleForm.companyId) {
        params.companyId = this.ruleForm.companyId;
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$post(
            this.stu != "edit"
              ? "/biz/mercantile/agency/insert"
              : "/biz/mercantile/agency/modify",
            params
          )
            .then((res) => {
              if (res.status == "0") {
                if (this.stu != "edit") {
                  if (this.contract == "contract") {
                    this.$message({
                      type: "success",
                      message: "新增成功",
                    });
                    this.$emit("contractBack",res.data.agencyId);
                  } else {
                    this.$message({
                      type: "success",
                      message: "新增成功",
                    });
                    this.$router.push({
                      path: "/web/agentSuccess",
                      query: {
                        agencyId: res.data.agencyId,
                        trainTypeIds: res.data.trainTypeIds.toString(),
                        agencyCooperationType: params.agencyCooperationType,
                        contract: this.contract,
                      },
                    });
                  }
                } else {
                  if (res.data) {
                    this.$confirm(
                      "你要取消代理类型" +
                        '"' +
                        res.data +
                        '"' +
                        "已在此代理商账号-数据类型中配置,请先取消账号中在数据类型的配置",
                      "提示",
                      {
                        confirmButtonText: "确定",
                        confirmButtonClass: "confirmButtonClass",
                        showCancelButton: false,
                      }
                    )
                      .then(() => {})
                      .catch(() => {});
                  } else {
                    this.$router.push({
                      path: "/web/agentList",
                      query: {
                        refrsh: true,
                      },
                    });
                  }
                }
                this.dohandleOk();
              }
            })
            .catch(() => {
              return;
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    getInfo(agencyId) {
      this.$post("/biz/mercantile/agency/getInfo", { agencyId })
        .then((ret) => {
          console.log(ret);
          this.ruleForm = {
            ...this.ruleForm,
            ...ret.data,
          };
          this.ruleForm.agencyIdcardFront = ret.data.agencyIdcardFrontUrl;
          this.ruleForm.agencyIdcardFrontKey = ret.data.agencyIdcardFront;
          this.ruleForm.agencyIdcardBack = ret.data.agencyIdcardBackUrl;
          this.ruleForm.agencyIdcardBackKey = ret.data.agencyIdcardBack;
          this.ruleForm.businessLicense = ret.data.businessLicenseUrl;
          this.ruleForm.businessLicenseKey = ret.data.businessLicense;
          this.ruleForm.legalIdcardBack = ret.data.legalIdcardBackUrl;
          this.ruleForm.legalIdcardBackKey = ret.data.legalIdcardBack;
          this.ruleForm.legalIdcardFront = ret.data.legalIdcardFrontUrl;
          this.ruleForm.legalIdcardFrontKey = ret.data.legalIdcardFront;
          this.ruleForm.cooperationTime = [
            ret.data.agencyStartDate.replaceAll("/", "-"),
            ret.data.agencyEndDate.replaceAll("/", "-"),
          ];
          this.ruleForm.areaId = ret.data.areaIds;
          this.ruleForm.trainTypeId = ret.data.trainTypeIds;
          this.ruleForm.companyId = ret.data.companyId;
        })
        .catch((err) => {
          return;
        });
    },
    // 点击取消
    doCancel() {
      this.$router.push({
        path: "/web/agentList",
        query: {
          refrsh: true,
        },
      });
    },
  },
};
</script>
<style lang="less">
.el-textarea {
  .el-textarea__inner {
    min-height: 10rem !important;
    resize: none;
  }
}
</style>
